<!-- 收货人地址 -->
<template>
  <div class="setaddress">
    <h6>收货人信息<button @click='switchPop'>新增收货地址</button></h6>
    <div class="box">
    	<div class='none'   v-if='!address||address.length<1'>暂无收货人信息，请添加收货人地址</div>
    	<div class="list" v-if='address' v-for='i,n in address' :key='n'>
        <div class="item" @click='switchAdr(i,n)'>
          <div class="td"><button class="choiceAd" :class='{choice:i.choice}' >{{i.name}}<svg class="icon"><use xlink:href="#iconwancheng"></use></svg></button></div>
          <div class="td">{{i.select.province}}</div>
          <div class="td">{{i.select.city}}</div>
          <div class="td">{{i.select.area}}</div>
          <div class="td">{{i.addressinfo}}</div>
          <div class="td">{{i.phone | formatPhoneHide(4)}}</div>
          <div class="td"><span v-show='i.default==1' class="sp">默认地址</span></div>
        </div>
        <div class="menus">
          <button v-show='i.default!=1'  @click='setDefault(i,n)'>设为默认地址</button>
          <button @click='deitAdr(i)'>编辑</button>
          <button v-show='address.length>1' @click='delAdr(n,i)'>删除</button>
        </div>
      </div>
    </div>
    <addaddress v-if='switchs'  :initData='editAddres' @exportClose='switchPop' @exportData='getAddress'></addaddress>
  </div>
</template>
<script>
import addaddress from '@/components/addaddress/addaddress.vue';//新增收货人地址
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'setaddress',
  mixins: [mixin],//混入
  components: {
    addaddress
  },
  data(){
  	return {
      switchs:false,//显示隐藏新增地址弹窗
  		// address:[],//地址列表
      editAddres:null,//要修改的地址
  	}
  },
  props: {
    msg: String,
    address:null,//地址列表
  },
  methods:{
    switchPop(){
      // 开关设置地址弹窗
      this.switchs = !this.switchs
      this.editAddres = {
        name:'',//收货人
        select: { province: '', city: '', area: '' },//地址选项框选择值
        addresId:0,//省，
        addresId1:0,//市，
        addresId2:0,//区，
        addressinfo:'',//详细地址
        phone:'',//手机号码
        code:'',//邮政编码
        phoneL:'',//座机号码前缀
        phoneR:'',//座机号码
        default:false,//是否设为默认地址---如果设为默认地址则会默认选择
        choice:false,//新增地址默认不选择---如果设为默认地址则会默认选择
        id:'',//用当前时间轴
        AddressID:0
      }
    },
    getAddress(v){
      // 获得组件传递的地址
      // this.address.push(v)
      this.$emit('exportDataInit',v)
      return;
      // if(v.default){
      //   v.choice = true;//设为默认就同时默认选择
      //   this.initDefault();
      //   for(let i in this.address){
      //     if(this.address[i].id==v.id){
      //       this.address.splice(i,1)
      //       this.address.unshift(v)
      //       return;
      //     }
      //   }
      //   this.address.unshift(v)
      // }else {
      //   for(let i in this.address){
      //     if(this.address[i].id==v.id){
      //       this.address.splice(i,1,v)
      //       return;
      //     }
      //   }
      //   this.address.push(v);//普通插入数据
      // }
    },
    switchAdr(item,index){
      // 切换地址
      this.initDefault(1);
      this.address[index].choice = true
      window.sessionStorage.setItem('choiceAddres',JSON.stringify(item))
      this.$emit('exportAdress',{item:item,method:'choice'});//抛出数据
    },
    setDefault(i,index){
      // 设置成为默认地址
      this.$MessageBox.confirm('是否设为默认地址', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass:'joinCar'
      }).then(() => {
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/SetDefaultAddress?AddressId='+i.AddressID,this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.initDefault(2);
                this.address[index].default = 1
                this.address[index].choice = true
                window.sessionStorage.setItem('choiceAddres',JSON.stringify(this.address[index]))
                this.address.unshift(this.address.splice(index,1)[0])
                this.$emit('exportAdress',{item:i,method:'choice'});//抛出数据
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
      }).catch(() => {
        // console.log('取消')
      });
    },
    deitAdr(item){
      // 编辑地址
      this.switchPop();
      this.editAddres = item
    },
    delAdr(index,i){
      // 删除地址
      // 如果是默认地址自动向下默认
      this.$MessageBox.confirm('是否删除地址', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass:'joinCar'
      }).then(() => {
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/delAddress?addrId='+i.AddressID,this.keys())
          .then(res => {
              if(res.data.Success){
                this.address.splice(index,1);
                this.address[0].choice=true
                this.$emit('exportAdress',{item:this.address[0],method:'choice'});//抛出数据
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }).catch(() => {
        console.log('再逛逛')
      });
    },
    initDefault(v){
      if(!v){
        for(let i in this.address){
          this.address[i].default = 0
          this.address[i].choice = false
        }
      }else if(v==1){
        for(let i in this.address){
          this.address[i].choice = false
        }
      }else if(v==2){
        for(let i in this.address){
          this.address[i].default = 0
          this.address[i].choice = false
        }
      }
    }
  },
  mounted(){
    // this.address = [{
    //     name:'张敏',//收货人
    //     select: { province: '广东省', city: '深圳市', area: '宝安区' },//地址选项框选择值
    //     addressinfo:'塘尾地铁站标凡科技大厦6楼',//详细地址
    //     phone:'13955454544',//手机号码
    //     code:'',//邮政编码
    //     phoneL:'',//座机号码前缀
    //     phoneR:'',//座机号码
    //     default:1,//是否设为默认地址
    //     choice:true,//新增地址默认不选择
    //     id:1,//唯一标识符
    //   },{
    //     name:'张敏1',//收货人
    //     select: { province: '广东省', city: '深圳市', area: '宝安区' },//地址选项框选择值
    //     addressinfo:'塘尾地铁站标凡科技大厦6楼',//详细地址
    //     phone:'13955454544',//手机号码
    //     code:'',//邮政编码
    //     phoneL:'',//座机号码前缀
    //     phoneR:'',//座机号码
    //     default:0,//是否设为默认地址
    //     choice:false,//新增地址默认不选择
    //     id:2,//唯一标识符
    //   }]
    this.$nextTick(()=>{
      // 初始默认选择
      var _sessionaddres = JSON.parse(window.sessionStorage.getItem('choiceAddres'))
      var _choice=false;
      if(_sessionaddres){
        for(let i in this.address){
          this.address[i].choice=false
        }
        for(let i in this.address){
          if(this.address[i].AddressID==_sessionaddres.AddressID){
            this.address[i].choice=true
            this.$emit('exportAdress',this.address[i]);//抛出数据
          }
        }
      }else{
        for(let i in this.address){
          this.address[i].choice=false
        }
        for(let i in this.address){
          if(this.address[i].choice){
            _choice = true
            window.sessionStorage.setItem('choiceAddres',JSON.stringify(this.address[i]))
            this.$emit('exportAdress',this.address[i]);//抛出数据
          }
        }
        if(!_choice&&this.address){
          this.address[0].choice=true
          this.$emit('exportAdress',{item:this.address[0],method:'choice'});//抛出数据
        }
      }
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.setaddress {
  h6 {
  	font-size: 20px;letter-spacing:3px;position: relative;padding-left:10px;margin-bottom:10px;
  	button {
  		float:right;background: none;color:#3f69bb;margin-right: 20px;margin-top:5px;
  	}
  	&:after {
  		content: '';display: block;width: 2px;height: 20px;background: #d65d6e;position: absolute;top:4px;left:0px;
  	}
  }
  .box {
  	min-height: 98px;border:1px solid #e6e6e6;padding:10px 15px;max-height: 200px;overflow-y: auto;
    &::-webkit-scrollbar{display:block;position:fixed;width:10px;background:white;}
  	.none {
  		color:#868686;
  	}
    .list {
      position: relative;margin-bottom:10px;
      .item {
        width: 100%;display: flex;justify-content: flex-start;height: 30px;cursor: pointer;
        .td {
          font-size: 14px;color:#212121;height: 30px;line-height: 30px;margin-right: 15px;
          .choiceAd {
            background: #fff;border:1px solid #e5e5e5;width: 140px;height: 30px;line-height: 28px;position: relative;overflow: hidden;font-weight: bold;
            svg {
              position: absolute;width: 11px;height: 11px;fill: #fff;bottom:1px;right:1px;z-index: 9;visibility: hidden;
            }
            &:after {
              content: '';display: block;position: absolute;width: 30px;height:30px;background: #ff5152;top:13px;right:-15px;transform: rotate(45deg);z-index: 8;visibility: hidden;
            }
          }
          .choice {
            border-color:#ff5152;
            svg {
              visibility: visible;
            }
            &:after {
              visibility: visible;
            }
          }
          .sp {
            display: block;width: 60px;height: 20px;line-height: 20px;margin-top:5px;background: #989898;color:#fff;font-size: 12px;text-align: center;
          }
        }
      }
      .menus {
        position: absolute;top:0px;right:0px;height: 30px;visibility: hidden;padding-right:10px;
        button {
          height: 30px;line-height: 30px;background: none;color:#3f69bb;margin-left:20px;
        }
      }
      &:hover {
        background: #fff5f6;
        .menus {
          visibility: visible;
        }
      }
    }
  }
}
</style>
