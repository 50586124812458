<!-- 新增收货人地址 -->
<template>
  <div class="addaddress">
    <div class="box">
      <button class="close" @click='switchPop'><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
      <h6 class='h6'>{{addOrEdit===0?'新增收货地址':'编辑收货地址'}}</h6>
      <div class="group-set">
        <div class="title">
          <i>*</i>收货人
        </div>
        <div class="cnt">
          <input type="text" v-model='addressData.name' @focus='noneErr("name")' :placeholder="'收货人'">
          <p class="err" :class='{errS:addressDataErr.name}'>{{addressDataErr.name}}</p>
        </div>
      </div>
      <div class="group-set">
        <div class="title">
          <i>*</i>所在地区
        </div>
        <div class="cnt">
          <!-- <input type="text" > -->
    <!--       <v-distpicker :class='{err:addressDataErr.select}' :placeholders="placeholders" :province="addressData.select.province" :city="addressData.select.city" :area="addressData.select.area" ref='addressData' @selected='choiceAddress'></v-distpicker> -->
          <el-select @focus.nactive='noneErr("addresSlt")'  v-model="optionsObj.value" placeholder="请选择" @change='change'>
            <el-option v-for="item,index in optionsObj.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
          <el-select @click.nactive='noneErr("addresSlt")' v-model="optionsObj1.value" placeholder="请选择" @change='change1'>
            <el-option  v-for="item,index in optionsObj1.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
          <el-select @click.nactive='noneErr("addresSlt")'  v-model="optionsObj2.value" placeholder="请选择" @change='change2'>
            <el-option v-for="item,index in optionsObj2.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
          <p class="err" :class='{errS:addressDataErr.addresSlt}'>{{addressDataErr.addresSlt}}</p>
        </div>
      </div>
      <div class="group-set">
        <div class="title">
          <i>*</i>详细地址
        </div>
        <div class="cnt">
          <input type="text"  @focus='noneErr("addressinfo")' v-model='addressData.addressinfo'  :placeholder="'详细地址'">
          <p class="err" :class='{errS:addressDataErr.addressinfo}'>{{addressDataErr.addressinfo}}</p>
        </div>
      </div>
      <div class="group-set">
        <div class="title">
          <i>*</i>手机号码
        </div>
        <div class="cnt">
          <input type="text"  @focus='noneErr("phone")' v-model='addressData.phone'  :placeholder="'手机号码'">
          <p class="err" :class='{errS:addressDataErr.phone}'>{{addressDataErr.phone}}</p>
        </div>
      </div>
      <div class="group-set">
        <div class="title">
          <i class="none">*</i>邮政编码
        </div>
        <div class="cnt">
          <input type="number" @focus='noneErr("code")' v-model='addressData.code' placeholder='邮政编码'>
          <p class="err" :class='{errS:addressDataErr.code}'>{{addressDataErr.code}}</p>
        </div>
      </div>
      <div class="group-set">
        <div class="title">
          <i class="none">*</i>座机号码
        </div>
        <div class="cnt zj">
          <div class="zjs">
            <input type="number" @focus='noneErr("phoneL")' v-model='addressData.phoneL' placeholder='区号'><span class='sp'>-</span>
            <input type="number" @focus='noneErr("phoneR")' v-model='addressData.phoneR' placeholder='座机号码'>
          </div>
          <p class="err" :class='{errS:addressDataErr._phone}'>{{addressDataErr._phone}}</p>
        </div>
      </div>
      <div class="discern">
        <textarea placeholder="按格式粘贴文本，可自动识别姓名，地址，电话                          如：张三，广东省深圳市宝安区宝安大道塘尾地铁C出口标帆科技大厦，13888888888" v-model='discernData'></textarea>
        <button @click="discernBtn"><span></span>点击识别地址</button>
      </div>
      <div class="default">
        <button @click='switchBtn'><span><svg v-if='addressData.default'><use xlink:href="#iconwancheng"></use></svg></span>设为默认地址</button>
      </div>
      <div class="menus">
        <button @click='save'>保存</button>
        <button @click='switchPop'>取消</button>
      </div>
    </div>
  </div>
</template>
<script>
// import VDistpicker from 'v-distpicker'
import {regPhone} from '../../assets/js/common.js' //公用js
export default {
  name: 'addaddress',
  components: {
    // VDistpicker
  },
  data(){
  	return {
      placeholders: {
          province:"省份",
          city:"城市",
          area:"区域"
      },//地址选项框初始值
      addressData:{
        name:'',//收货人
        select: { province: '', city: '', area: '' },//地址选项框选择值
        addressinfo:'',//详细地址
        phone:'',//手机号码
        code:'',//邮政编码
        phoneL:'',//座机号码前缀
        phoneR:'',//座机号码
        default:false,//是否设为默认地址---如果设为默认地址则会默认选择
        choice:false,//新增地址默认不选择---如果设为默认地址则会默认选择
        id:'',//用当前时间轴
        addresId:0,//省，
        addresId1:0,//市，
        addresId2:0,//区，
      },
      addressDataErr:{
        name:'',//收货人
        addresId:'',//地址选项框选择值--省
        addresId1:'',//地址选项框选择值--市
        addresId2:'',//地址选项框选择值--区
        addressinfo:'',//详细地址
        phone:'',//手机号码
        code:'',//邮编
        _phone:'',//座机号码
      },
      discernData:'',//识别文本域内容
      // discernData:'张敏，广东省深圳市宝安区塘尾地铁站标凡科技大厦6楼，13955454544',//识别文本域内容1
      optionsObj:{
        options:[
          {value:0,
          label:'省'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      },
      optionsObj1:{
        options:[
          {value:0,
          label:'市'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      },
      optionsObj2:{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      },
      addOrEdit:0,//新增还是修改
  	}
  },
  props: {
    initData: Object,
  },
  methods:{
    noneErr(v){
      // 隐藏提示
      // console.log(v)
      for(let i in this.addressDataErr){
        if(i==v){
          this.addressDataErr[i]=''
        }
      }
      if(v=='phoneL'||v=='phoneR'){
        this.addressDataErr._phone=''
      }
    },
    change(v,fn){
      // 地址栏选择--省
      this.$set(this,'optionsObj1',{
        options:[
          {value:0,
          label:'市'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.$set(this,'optionsObj2',{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.addressData.addresId = v;
      if(v!=0){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainCityList?ProvinceId='+v,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [{value:0,
                    label:'市'}];
                for(let i in res.data.Data){
                  var _obj = {};
                  _obj.value = res.data.Data[i].CityID
                  _obj.label = res.data.Data[i].CityName
                  _data.push(_obj)
                }
                this.$set(this.optionsObj1,'options',_data)
                if(fn){
                  fn();
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    change1(v,fn){
      // 地址栏选择--市
      this.addressData.addresId1 = v
      this.$set(this,'optionsObj2',{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      if(v!=0){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainAreaList?ProvinceID='+this.addressData.addresId+'&CityId='+v,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [{value:0,
                    label:'区'}];
                for(let i in res.data.Data){
                  var _obj = {};
                  _obj.value = res.data.Data[i].AreaID
                  _obj.label = res.data.Data[i].AreaName
                  _data.push(_obj)
                }
                this.$set(this.optionsObj2,'options',_data)
                if(fn){
                  fn();
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    change2(v,fn){
      // 地址栏选择--区
      this.addressData.addresId2 = v;
      if(fn){
        fn();
      }
    },
    switchPop(){
      // 开关设置地址弹窗
      this.$emit('exportClose')
    },
    switchBtn(){
      //切换设为默认
      this.addressData.default = !this.addressData.default
    },
    choiceAddress(data){
      // // 地址选择回调
      // // console.log(data)
      // this.addressData.select.province = data.province.value
      // this.addressData.select.city = data.city.value
      // this.addressData.select.area = data.area.value
    },
    discernBtn(){
      if(this.discernData==''){
        this.$MessageBox.alert('请按格式填写文本域',{type:'error'});
        return;
      }
      for(let i in this.addressDataErr){
        this.addressDataErr[i]=''
      }
      this._axios({then:()=>{
        this.axios.post('https://ctumin.51ctu.com:8018/logistics/adress_web?',{
          text: this.discernData,
        })
        .then(res => {
            this.$Message({
                message: '识别成功!',
                type: 'success',
                duration:1500,
                // offset:100
            });
            this.addressData.name=res.data.data[0].name
            this.addressData.phone=res.data.data[0].mobile
            this.addressData.addressinfo=res.data.data[0].detail
            for(let i in this.optionsObj.options){
              if(this.optionsObj.options[i].label==res.data.data[0].province_name){
                this.optionsObj.value = this.optionsObj.options[i].value
                this.change(this.optionsObj.value,()=>{
                  for(let i in this.optionsObj1.options){
                    if(this.optionsObj1.options[i].label==res.data.data[0].city_name){
                      this.optionsObj1.value = this.optionsObj1.options[i].value
                      this.change1(this.optionsObj1.value,()=>{
                        for(let i in this.optionsObj2.options){
                          if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                            this.optionsObj2.value = this.optionsObj2.options[i].value
                            this.change2(this.optionsObj2.value)
                          }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                             this.optionsObj2.value = this.optionsObj2.options[i].value
                             this.change2(this.optionsObj2.value)
                          }
                        }
                      })
                    }else if(this.optionsObj1.options[i].label==res.data.data[0].city_shortname){
                       this.optionsObj1.value = this.optionsObj1.options[i].value
                       this.change1(this.optionsObj1.value,()=>{
                          for(let i in this.optionsObj2.options){
                            if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                              this.optionsObj2.value = this.optionsObj2.options[i].value
                              this.change2(this.optionsObj2.value)
                            }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                               this.optionsObj2.value = this.optionsObj2.options[i].value
                               this.change2(this.optionsObj2.value)
                            }
                          }
                       })
                    }
                  }
                })
              }else if(this.optionsObj.options[i].label==res.data.data[0].province_shortname){
                 this.optionsObj.value = this.optionsObj.options[i].value
                 this.change(this.optionsObj.value,()=>{
                    for(let i in this.optionsObj1.options){
                      if(this.optionsObj1.options[i].label==res.data.data[0].city_name){
                        this.optionsObj1.value = this.optionsObj1.options[i].value
                        this.change1(this.optionsObj1.value,()=>{
                          for(let i in this.optionsObj2.options){
                            if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                              this.optionsObj2.value = this.optionsObj2.options[i].value
                              this.change2(this.optionsObj2.value)
                            }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                               this.optionsObj2.value = this.optionsObj2.options[i].value
                               this.change2(this.optionsObj2.value)
                            }
                          }
                        })
                      }else if(this.optionsObj1.options[i].label==res.data.data[0].city_shortname){
                         this.optionsObj1.value = this.optionsObj1.options[i].value
                         this.change1(this.optionsObj1.value,()=>{
                            for(let i in this.optionsObj2.options){
                              if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                                this.optionsObj2.value = this.optionsObj2.options[i].value
                                this.change2(this.optionsObj2.value)
                              }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                                 this.optionsObj2.value = this.optionsObj2.options[i].value
                                 this.change2(this.optionsObj2.value)
                              }
                            }
                         })
                      }
                    }
                 })
              }
            }

          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    save(){
      // 保存新增地址
      var require = true;//判断必填
      this.addressDataErr = {
        name:'',//收货人
        // addresId:'',//地址选项框选择值--省
        // addresId1:'',//地址选项框选择值--市
        // addresId2:'',//地址选项框选择值--区
        addresSlt:'',//地址选择
        addressinfo:'',//详细地址
        phone:'',//手机号码
        code:'',//邮编
        _phone:'',//座机号
      }
      for(let i in this.addressData){
        if(i!='addresId'&&i!='addresId1'&&i!='addresId2'&&i!='code'&&i!='phoneL'&&i!='phoneR'&&i!='default'&&i!='choice'&&i!='id'&&this.addressData[i]==''){
          if(i=='name'){
            this.addressDataErr[i] = '请填写收货人'
          }
          if(i=='addressinfo'){
            this.addressDataErr[i] = '请填写详细地址'
          }
          if(i=='phone'){
            this.addressDataErr[i] = '请填写手机号码'
          }
          require = false
        }
      }
      // for(let i in this.addressData.select){
      //   if(this.addressData.select[i]==''){
      //     this.addressDataErr.select='请选择地址'
      //     require = false
      //   }
      // }
      // if(this.addressData.addresId==0){
      //   this.addressDataErr.addresId='请选择省'
      //   require = false
      // }
      // if(this.addressData.addresId1==0){
      //   this.addressDataErr.addresId1='请选择市'
      //   require = false
      // }
      // if(this.addressData.addresId2==0){
      //   this.addressDataErr.addresId2='请选择区'
      //   require = false
      // }
      if(this.addressData.addresId==0||this.addressData.addresId1==0||this.addressData.addresId2==0){
          this.addressDataErr.addresSlt='请选择所在地区'
          require = false
      }
      var pattern = /^[0-9]{6}$/;
      if(this.addressData.code&&!pattern.test(this.addressData.code)){

        this.addressDataErr.code='请填写正确邮编'
        return;
      }

      var _phones;
      if(!this.addressData.phoneL&&!this.addressData.phoneR){
         _phones = ''
      }else {
        _phones = this.addressData.phoneL+'-'+this.addressData.phoneR
      }
      var pattern1 = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
      if(_phones&&!pattern1.test(_phones)){
        this.addressDataErr._phone='请填写正确座机号'
        return;
      }
      if(!require) {
        // this.$MessageBox.alert('带 * 号必填',{type:'error'});
        return
      };
      if(!regPhone(this.addressData.phone)){
        // this.addressData.phone = ''
        this.addressDataErr.phone = '请填写正确手机号码'
        // this.$MessageBox.alert('请填写正确手机号',{type:'error'});
        return;
      }
      this._axios({then:()=>{
      //   addressData:{
      //   name:'',//收货人
      //   select: { province: '', city: '', area: '' },//地址选项框选择值
      //   addressinfo:'',//详细地址
      //   phone:'',//手机号码
      //   code:'',//邮政编码
      //   phoneL:'',//座机号码前缀
      //   phoneR:'',//座机号码
      //   default:false,//是否设为默认地址---如果设为默认地址则会默认选择
      //   choice:false,//新增地址默认不选择---如果设为默认地址则会默认选择
      //   id:'',//用当前时间轴
      // },
      this.addressData.default = this.addressData.default==1?true:false
        // var _phones;
        // if(!this.addressData.phoneL&&!this.addressData.phoneR){
        //    _phones = ''
        // }else {
        //   _phones = this.addressData.phoneL+'-'+this.addressData.phoneR
        // }
        var _s='',_c='',_q='';
        for(let i in this.optionsObj.options){
          if(this.optionsObj.options[i].value==this.optionsObj.value){
            _s = this.optionsObj.options[i].label
          }
        }
        for(let i in this.optionsObj1.options){
          if(this.optionsObj1.options[i].value==this.optionsObj1.value){
            _c = this.optionsObj1.options[i].label
          }
        }
        for(let i in this.optionsObj2.options){
          if(this.optionsObj2.options[i].value==this.optionsObj2.value){
            _q = this.optionsObj2.options[i].label
          }
        }
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/SaveAddress?ContactName='+this.addressData.name+'&Address='+(_s+_c+_q+this.addressData.addressinfo)+'&Mobile='+this.addressData.phone+'&ProvinceID='+this.addressData.addresId+'&CityID='+this.addressData.addresId1+'&AreaID='+this.addressData.addresId2+'&PostCode='+this.addressData.code+'&telphone='+_phones+'&defAddr='+this.addressData.default+'&addrId='+this.addOrEdit,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
              //   this.$store.dispatch("cmtSetUser",null);//赋值
              //   this._usermet({method:'del'});//存本地
              //   this._token({method:'del'});//存本地
              //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
              // }
              if(this.addOrEdit==0){
                this.$emit('exportClose')
                this.$emit('exportData',res.data.Data)
                this.$Message({message:'新增地址成功！',type:'success',duration:1500})
              }else{
                this.$emit('exportClose')
                this.$emit('exportData',res.data.Data)
                this.$Message({message:'修改地址成功！',type:'success',duration:1500})
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          // 用户不存在
          var pms = this.$route.fullPath + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        }})
      // return;
      // if(!this.addressData.id){
      //   var _id = Date.now()
      //   this.addressData.id = _id
      // }
      // // console.log(this.addressData);//保存的地址数据需要抛出到父容器
      // this.$MessageBox.alert('新增地址成功',{type:'success'}).then(() => {
      //   this.$emit('exportClose')
      //   this.$emit('exportData',this.addressData)
      // });
    }
  },
  // watch:{
  //   initData(_old,_new){
  //     console.log(_old,_new)
  //   }
  // },
  mounted(){
    this.$nextTick(()=>{
      // 初始化默认值
      if(this.initData){
        this.addOrEdit=this.initData.AddressID
        this.addressData.name = this.initData.name
        this.addressData.select.province = this.initData.select.province
        this.addressData.select.city = this.initData.select.city
        this.addressData.select.area = this.initData.select.area
        this.addressData.addressinfo = this.initData.addressinfo
        this.addressData.phone = this.initData.phone
        this.addressData.code = this.initData.code?this.initData.code:''
        this.addressData.phoneL = this.initData.phoneL?this.initData.phoneL:''
        this.addressData.phoneR = this.initData.phoneR?this.initData.phoneR:''
        this.addressData.default = this.initData.default
        this.addressData.choice = this.initData.choice

        // console.log(this.addressData.code)
      }
      // end
      // 初始化地址
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainProvinceList',this.keys())
        .then(res => {
            if(res.data.Success){
              var _data = [{value:0,
                  label:'省'}];
              for(let i in res.data.Data){
                var _obj = {};
                _obj.value = res.data.Data[i].ProvinceID
                _obj.label = res.data.Data[i].ProvinceName
                _data.push(_obj)
              }
              this.$set(this.optionsObj,'options',_data)
              for(let i in this.optionsObj.options){
                if(this.optionsObj.options[i].value==this.initData.addresId){
                  this.optionsObj.value = this.optionsObj.options[i].value
                  this.change(this.optionsObj.value,()=>{
                    for(let i in this.optionsObj1.options){
                      if(this.optionsObj1.options[i].value==this.initData.addresId1){
                        this.optionsObj1.value = this.optionsObj1.options[i].value
                        this.change1(this.optionsObj1.value,()=>{
                          for(let i in this.optionsObj2.options){
                            if(this.optionsObj2.options[i].value==this.initData.addresId2){
                              this.optionsObj2.value = this.optionsObj2.options[i].value
                              this.change2(this.optionsObj2.value)
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // this.optionsObj={
      //   options:[
      //     {value:0,
      //     label:'省'},
      //     {value:1,
      //     label:'火星'}
      //   ],//选项卡---配送方式
      //   value:0,//选项卡配送方式值
      // }
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.addaddress {
  width: 100%;height: 100%;position: fixed;background: rgba(0,0,0,0.6);top:0px;left:0px;z-index: 999;
  .box {
    width: 470px;height: 585px;background: #fff;border-radius: 10px;position: absolute;top:calc((100% - 585px)/2);left:calc((100% - 470px)/2);
    .close {
      position: absolute;top:10px;right:15px;background: none;
      svg {
        fill: #838383;width: 20px;height: 20px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .h6 {
      height: 40px;line-height: 40px;background: #f3f3f3;font-size: 15px;padding-left:15px;color:#898989;border-top-left-radius: 10px;border-top-right-radius: 10px;margin-bottom:15px;
    }
    .group-set {
      display: flex;margin:0 30px;justify-content:flex-start;margin-bottom:5px;
      .title {
        width: 100px;line-height: 35px;margin-left:5px;
        i {
          font-style: normal;vertical-align: -3px;color:#d43241;margin-right: 3px;
        }
        .none {
          visibility: hidden;
        }
      }
      .cnt {
        .el-select {
          width: 93px;margin-right: 5px;
        }
        /deep/.el-input__inner {
          border-radius:0px;border-color:#d2d2d2;
        }
        input {
          border:1px solid #d2d2d2;width: 278px;height: 33px;padding-left:10px;
          &::placeholder {
            color:#ccc;
          }
        }
        // /deep/.distpicker-address-wrapper {
        //   select {
        //     width: 94px;border-radius: 0px;border-color:#d2d2d2;
        //   }
        // }
        .err {
          border-color:#fa5467;color:#fa5467;font-size: 13px;display: block;height: 18px;visibility: hidden;
          &::placeholder {
            color:#fa5467;
          }
          input {
             border-color:#fa5467;
          }
        }
        .errS {
          visibility: visible;
        }
        /deep/.err {
          select {
            border-color:#fa5467;
          }
          input {
             border-color:#fa5467;
          }
        }
      }
      .zj {
        .zjs {
          display: flex;justify-content: flex-start;
          .sp {
            line-height: 35px;margin:0 2px;color:#797979;
          }
          input {
            &:nth-child(1) {
              width: 78px;
            }
            &:nth-child(3) {
              width: 178px;
            }
          }
        }
      }
    }
    .discern {
      // margin-top:15px;
      textarea {
        font-size:14px;color:#333;line-height: 16px;
        background: #f2f2f2;width: 360px;height: 50px;border-radius: 5px; margin:0 auto;display: block;padding:10px;
        &::placeholder {
          font-size:12px;
        }
      }
      button {
        background: none;margin:0 auto;display: block;display: flex;justify-content: flex-start;color:#197ba0;margin-top:5px;
        span {
          border:1px solid #197ba0;width: 12px;height: 12px;display: block;margin-top:3px;border-radius: 50%;margin-right: 4px;position: relative;
          &:after {
            content: '';display: block;width: 6px;height: 6px;background: #197ba0;position: absolute;top:3px;left:3px;border-radius: 50%;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .default {
      margin-top:10px;
      button {
        background: none;display: flex;justify-content: flex-start;color:#747474;margin-left:40px;
        span {
          display: block;width: 14px;height: 14px;border:1px solid #f84660;border-radius: 2px;margin-top:2px;margin-right: 5px;
          svg {
            fill: #f84660;width: 10px;height: 10px;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .menus {
      display: flex;justify-content: space-between;width: 130px;margin:0 auto;margin-top:15px;
      button {
        display: block;width: 60px;height: 30px;font-size: 12px;background: #f84660;border-radius: 5px;color:#fff;
        &:nth-child(2){
          background: #fff;border:1px solid #d1d1d1;color:#333;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
