<!-- 结算页面---发票 -->
<template>
  <div class="invoice">
    <h6>商品信息</h6>
    <div class='box'>
      <div class="title"><span @click='switchInoice'><svg v-show='invoices'><use xlink:href="#iconkefu"></use></svg></span><p @click='switchInoice'>我需要发票</p><button @click='switchs'>填写/修改发票信息</button></div>
      <p class="tip">注：开企业抬头发票须填写纳税人识别号，以免影响报销</p>
      <div class="info">
        <span>普通发票</span>
        <span>{{invoiceinfo.data1}}</span>
        <span>商品明细</span>
      </div>
    </div>
    <setinvoice v-show='setinvoices' @exportClose='switchs'  :invoiceinprops='invoiceinfo' @exportCloseInv='exportCloseInv'></setinvoice>
  </div>
</template>
<script>
import setinvoice from '@/components/setinvoice/setinvoice.vue';//底部滚动展示
export default {
  name: 'invoice',
  components:{
    setinvoice
  },
  data(){
    return {
      invoices:false,//是否需要开发票
      setinvoices:false,//显示关闭开发票弹窗
      invoiceinfo:{},//开票信息
      voicePrices:0,//发票价格
    }
  },
  props: {
    voicePrice: Number
  },
  methods:{
    exportCloseInv(){
      // 信息不全取消发票
      this.invoices = false
      this.$emit('exportInvoice',null)
    },
    switchInoice(){
      // 开发票-取消开发票
      if(this.invoiceinfo.data8==0&&(this.invoiceinfo.data1==''||this.invoiceinfo.data2==''||this.invoiceinfo.data7=='')){
        this.$MessageBox.alert('发票信息带‘*’号的内容没有填写完整',{type:'error'});
        return;
      }
      if(this.invoiceinfo.data8==1&&(this.invoiceinfo.data1==''||this.invoiceinfo.data2=='')){
        this.$MessageBox.alert('发票信息带‘*’号的内容没有填写完整',{type:'error'});
        return;
      }
      this.invoices = !this.invoices
      if(this.invoices){
        this.$emit('exportInvoice',this.invoiceinfo)
      }else{
        this.$emit('exportInvoice',null)
      }
    },
    switchs(v){
      // 打开关闭开发票弹窗
      this.setinvoices = !this.setinvoices
      this.voicePrices = this.voicePrice
      // 打开发票弹窗--查询session中有没有值---判断新增/修改
      var _invoiceinfo = window.sessionStorage.getItem('invoiceinfo')
      if(_invoiceinfo){
        this.invoiceinfo = JSON.parse(_invoiceinfo)
      }else{
        this.invoiceinfo =  {
          data:this.voicePrice,
          data1:'',
          data2:'',
          data3:'',
          data4:'',
          data5:'',
          data6:'',
          data7:'',
          data8:"0",
        }//开票数据
      }
    }
  },
  mounted(){
    // 刷新页面--查询session中有没有值---判断新增/修改
    var _invoiceinfo = window.sessionStorage.getItem('invoiceinfo')
    if(_invoiceinfo){
      this.invoiceinfo = JSON.parse(_invoiceinfo)
    }else{
      this.invoiceinfo =  {
        data:this.voicePrice,
        data1:'',
        data2:'',
        data3:'',
        data4:'',
        data5:'',
        data6:'',
        data7:'',
        data8:"0",
      }//开票数据
    }
  }
}
</script>
<style  lang="scss" scoped>
.invoice {
  h6 {
    font-size: 20px;letter-spacing:3px;position: relative;padding-left:10px;margin-bottom:10px;
    button {
      float:right;background: none;color:#3f69bb;margin-right: 20px;margin-top:5px;
    }
    &:after {
      content: '';display: block;width: 2px;height: 20px;background: #d65d6e;position: absolute;top:4px;left:0px;
    }
  }
  .box {
    border:1px solid #e4e4e4;padding:20px;
    .title {
      color:#373737;display: flex;justify-content: flex-start;align-items: center;font-weight: bold;
      span {
        display: block;width: 14px;height: 14px;border:1px solid #a75e6f;border-radius: 3px;display: flex;justify-content: center;align-items: center;margin-right: 15px;cursor: pointer;
        svg {
          width: 10px;height: 10px;fill: #a75e6f;
        }
      }
      p {
        cursor: pointer;margin-right: 50px;
      }
      button {
        background: none;font-weight: bold;color:#3750b4;
      }
    }
    .tip {
      color:#909090;padding-left:32px;margin-top:10px;font-size: 14px;
    }
    .info {
      padding-left:32px;margin-top:10px;
      span {
        font-size:14px;color:#353535;margin-right: 15px;
      }
    }
  }
}
</style>
