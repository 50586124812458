<!-- 商品详情 -->
<template>
  <div class="productinfo">
    <h6>商品信息<button @click='linkBuyCar'>返回购物车修改</button></h6>
    <div class="table">
      <el-row class='tr'>
        <el-col class='th' :span="4">商品信息</el-col>
        <el-col class='th' :span="4">数量</el-col>
        <!-- <el-col class='th' :span="4">运费</el-col> -->
        <el-col class='th' :span="4">小计</el-col>
      </el-row>
      <el-row class='tr' v-for='i,n in buylist' :key='n'>
        <el-col class='td' :span="4">
          <div class="img"><img :src="i.url"></div>
          <div class="info"><p>{{i.name}}</p><span>数量：{{i.DzproductnumberName}}</span></div>
          <div class="other"><p>规格：{{i.DzspecificationName}}</p><p>材质：{{i.DzmaterialName}}</p></div>
        </el-col>
        <el-col class='td' :span="4">{{i.number1}}</el-col>
        <!-- <el-col class='td' :span="4"><span class="express">{{i.express==0?'免运费':i.express}}</span></el-col> -->
        <el-col class='td' :span="4"><span class="price"><i>&yen;</i>{{i.price*i.number1}}</span></el-col>
      </el-row>
      <el-row class='tr'>
        <el-col class='td' :span="4">
          支付方式：
          <el-select v-model="value" placeholder="请选择" @change='change'>
            <el-option v-for="item,index in options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
        </el-col>
        <el-col class='td' :span="4">
          配送方式：
          <el-select v-model="optionsObj1.value" placeholder="请选择" @change='change1'>
            <el-option v-for="item,index in optionsObj1.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
        </el-col>
        <el-col class='td' :span="4">
          预计您的商品总净重：<span class='weight weight1'>{{weight}}kg</span>
          运费：<span v-show='shipPrice!=0' class='weight weight2'>&yen;{{shipPrice}}</span>
          <span  v-show='shipPrice==0' class='weight weight2'>包邮</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'productinfo',
  mixins: [mixin],//混入
  data(){
    return {
      // weight:10,//总净重
      // buylist:[
      //   // {url:require("../../assets/img/buycarlist/img.jpg"),name:'广告纸巾定做钱夹式纸巾荷包纸巾宣传餐厅纸巾印刷logo微微定原...',number:500,tip:'2层10片',tip1:'175*190mm',price:418,number1:1,id:1,_choice:false,express:0,},
      // ],//列表数据
      options:[{
        value: 0,
        label:'在线支付'
      }],//选项卡---支付方式
      value:0,//选项卡支付方式值
      // options1:[
      //   // {
      //   //   value: 0,
      //   //   label:'普通快递'
      //   // }, {
      //   //   value: 1,
      //   //   label:'加急快递'
      //   // },
      // ],//选项卡---配送方式
      // value1:null,//选项卡配送方式值
    }
  },
  props: {
    msg: String,
    buylist:null,//列表数据
    // options1:null,//选项卡---配送方式
    // value1:null,//选项卡配送方式值
    optionsObj1:{
      options:null,//选项卡---配送方式
      value:null,//选项卡配送方式值
    },
    weight:null,//总净重
    shipPrice:null,//运费
  },
  methods:{
    linkBuyCar(){
      this.$emit('backcar')
    },
    change(v){
      // this.$emit('exportProduct',{list:this.buylist,weight:this.weight,value:v,value1:this.value1})
    },
    change1(v){
      this.$emit('exportProduct',{value:v,method:'ship'})
    },
  },
  mounted(){
    this.$nextTick(()=>{
      // 默认传出的数据
      // this.$emit('exportProduct',{list:this.buylist,weight:this.weight,value:this.value,value1:this.value1})
      // end
      // this._axios({then:()=>{
      //   this.axios.post(process.env.VUE_APP_URL+'Shop/ObtainOrderMessage?CustomerId='+this.$store.state.user.CustomerID+'&JobIDs='+this.$route.query._ids+'&copies='+this.$route.query.nums,this.keys())
      //   .then(res => {
      //       if(res.data.Success){
      //         var _data = [];
      //         var _data1 = [];
      //         for(let i in res.data.Data.orderBill.JobList){
      //           var _obj = {}
      //           var _str = res.data.Data.orderBill.JobList[i].JobName.indexOf('[');
      //           var _end = res.data.Data.orderBill.JobList[i].JobName.indexOf(']');
      //           var _s = res.data.Data.orderBill.JobList[i].JobName.substring(_str+1,_end)
      //           _obj.url = this._src + res.data.Data.orderBill.JobList[i].ImageUrl
      //           _obj.name =res.data.Data.orderBill.JobList[i].JobName.substring(0,_str)
      //           _obj.number = _s.split('-')[2]
      //           _obj.tip = _s.split('-')[1]
      //           _obj.tip1 = _s.split('-')[0]
      //           _obj.number1 = res.data.Data.orderBill.JobList[i].JobNum
      //           _obj.price = res.data.Data.orderBill.JobList[i].Price
      //           _obj.express = res.data.Data.orderBill.JobList[i].ShipPrice
      //           _data.push(_obj)
      //         }
      //         for(let i in res.data.Data.orderBill.ShippingmethodList){
      //           var _obj1 = {}
      //           _obj1.value = res.data.Data.orderBill.ShippingmethodList[i].ShippingMethodID
      //           _obj1.label = res.data.Data.orderBill.ShippingmethodList[i].ShippingMethodName
      //           _data1.push(_obj1)
      //         }
      //         this.$set(this,'options1',_data1)
      //         this.$set(this,'value1',_data1[0].value)
      //         this.$set(this,'buylist',_data)
      //       }else{
      //         this.$MessageBox.alert(res.data.Error,{type:'error'});
      //       }
      //     }, res => {
      //       this.$MessageBox.alert('系统异常',{type:'error'});
      //     })
      // }})
    })
  }
}
</script>
<style  lang="scss" scoped>
.productinfo {
  h6 {
    font-size: 20px;letter-spacing:3px;position: relative;padding-left:10px;margin-bottom:10px;
    button {
      float:right;background: none;color:#3f69bb;margin-right: 20px;margin-top:5px;
    }
    &:after {
      content: '';display: block;width: 2px;height: 20px;background: #d65d6e;position: absolute;top:4px;left:0px;
    }
  }
  .table {
    border:1px solid #e5e5e5;padding:10px 20px;
    .tr {
      border-bottom:1px solid #e5e5e5;
      .el-col-4 {
        width: calc((100% - 620px)/2);text-align: center;
        &:nth-child(1) {
          width: 620px;text-align: left;padding-left:10px;
        }
      }
      .th {
        height: 60px;line-height: 60px;
      }
      .td {
        display: flex;justify-content: center;align-items: center;height: 150px;font-size: 14px;color:#313131;
        .img {
          height: 150px;width: 100px;display: flex;justify-content: center;align-items: center;
          img {
            display: block;max-height: 100%;max-width: 100%;
          }
        }
        .info {
          width: 200px;margin-right: 15px;margin-left:5px;
          p {
            text-overflow: -o-ellipsis-lastline; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;color:#383838;font-weight: bold;font-size: 14px;
          }
          span {
            color:#7b7b7b;font-size: 14px;margin-top:5px;display: block;
          }
        }
        .other {
          p {
            font-size: 14px;color:#8f8f8f;
          }
        }
        .express {
          color:#a81b2c;font-weight: bold;
        }
        .price {
          color:#a81b2c;font-weight: bold;
          i {
            font-style: normal;margin-right: 3px;
          }
        }
        &:nth-child(1) {
          justify-content: flex-start;
        }
      }
      &:last-child {
        border-bottom:0px;
        .el-col-4 {
          width: calc((100% - 580px)/2);display: flex;justify-content: flex-start;
          &:nth-child(3) {
             width: 580px;justify-content: flex-end;padding-right:10px;
          }
        }
        .td {
          height: 70px;font-size: 14px;
          .weight {
            font-size: 18px;font-weight: bold;
          }
          .weight1 {
            margin-right: 20px;
          }
          .weight2 {
            color:#a81b2c;
          }
          /deep/.el-select {
            width: 120px;
            .el-input__inner {
              // height: 35px;line-height: 35px;color:#333;
            }
          }
        }
      }
    }
  }
}
</style>
