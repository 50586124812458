<!-- 结算页---使用优惠券 -->
<template>
  <div class="usediscount">
  	<h6>优惠券</h6>
  	<div class="box">
  		<div class="list">
  			<div class="item"  :class='{active:i.choice}' @click='choicedis(n)' v-for='i,n in discountlist' :key='n'>
          <div class='head'>
            <div class="lt">
              <span>&yen;</span>{{i.discount}}
            </div>
            <div class="rt">
              <p>优惠券</p>
              <!-- <P>满{{i.meet}}使用</P> -->
              <p>{{i.meet==0?'无门槛':'满'+i.meet+'使用'}}</p>
            </div>
          </div>
          <div class="bottom">
            有效期至{{i.time.split('T')[0]}}
          </div>
          <div class="icon"><svg><use xlink:href="#iconwancheng"></use></svg></div>
        </div>
  		</div>
      <p class="nodis" v-if='!discountlist||discountlist.length<1'>暂无优惠券<button @click='discountcenter'>去领券>></button></p>
  		<p class="use">金额抵用<span>&yen;{{price | formatPrice}}</span>使用优惠券{{number}}张，优惠{{price | formatPrice}}元</p>
  	</div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'usediscount',
  mixins: [mixin],//混入
  data(){
  	return {
  		// discountlist:[
    //     {time:'2020-10-08',discount:10,meet:99,choice:false},
  		// 	{time:'2020-10-08',discount:10,meet:99,choice:false},
  		// ],
      price:0,//抵用价格
      number:0,//使用张数
  	}
  },
  props: {
    msg: String,
    discountlist:null,//优惠券列表
  },
  methods:{
    discountcenter(){
      // 领券中心
      this._router({then:()=>{
        this.$router.push({path: "/discountcenter.html"})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/discountcenter.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    choicedis(index){
      // 选择优惠券
      if(!this.discountlist[index].choice){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/CheckCouponcode?CouponcodeId='+this.discountlist[index].Id+'&JobIDs='+this._spf(this.$route.query._ids),this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                for(let i in this.discountlist){
                  this.discountlist[i].choice = false
                }
                this.discountlist[index].choice = !this.discountlist[index].choice
                this.$emit('exportCount',{method:'choice'})
                this.setcount();
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }else{
        this.discountlist[index].choice = !this.discountlist[index].choice
        this.$emit('exportCount',{method:'nochoice'})
        this.setcount();
      }
    },
    setcount(){
      // 计算优惠券
      var price = 0,number =0;
      for(let i in this.discountlist){
        if(this.discountlist[i].choice){
          price+=this.discountlist[i].discount
          number+=1
        }
      }
      this.price = price
      this.number =  number
      // this.$emit('exportCount',this.price)
    }
  }
}
</script>
<style  lang="scss" scoped>
.usediscount {
	h6 {
	    font-size: 20px;letter-spacing:3px;position: relative;padding-left:10px;margin-bottom:10px;
	    button {
	      float:right;background: none;color:#3f69bb;margin-right: 20px;margin-top:5px;
	    }
	    &:after {
	      content: '';display: block;width: 2px;height: 20px;background: #d65d6e;position: absolute;top:4px;left:0px;
	    }
	}
	.box {
		border:1px solid #e4e4e4;padding:20px;
    .list {
      // display: flex;justify-content: flex-start;
      zoom:1;
      .item {
        width: 200px;height: 100px;margin-right: 20px;cursor: pointer; position: relative;overflow: hidden;border-top-left-radius: 10px;border-top-right-radius: 10px;float:left;margin-bottom:10px;
        .head {
          display: flex;justify-content: flex-start;
          position: relative;
          width: 200px;
          height: 70px;
          background-image:
          radial-gradient(
            circle at 9px -1px,
            transparent 8px,
            #f3d98b 6px,
            #f3d98b 0px);
          background-size:  20px 200px;
          background-position: 0 0, 200px 0;
          background-repeat-y: no-repeat;
          .lt {
            width: 100px;font-size: 46px;color:#f75950;text-align: center;line-height: 75px;
          }
          .rt {
            width: 100px;
            p {
              color:#f75950;text-align: center;
              &:nth-child(1) {
                font-weight: bold;font-size: 20px;margin-top:15px;
              }
              &:nth-child(2) {
                font-size:14px;
              }
            }
          }
        }
        .bottom {
          text-align: center;color:#818181;font-size: 14px;height: 28px;line-height: 28px;border:1px solid #ededed;border-top:0px;
        }
        .icon {
          width: 35px;height: 35px;position: absolute;background: #f64b43;bottom:-17px;right:-17px;transform: rotate(45deg);visibility: hidden;
          svg {
            fill: #fff;transform: rotate(-45deg);width: 12px;height: 12px;float:left;margin-top:11px;margin-left:1px;
          }
        }
        &:after {
          content: "";display: block;position: absolute;width: 196px;height: 96px;border:2px solid #f64b43;top:0px;left:0px;border-top-left-radius: 10px;border-top-right-radius: 10px;visibility: hidden;
        }
      }
      .active {
        .icon {
          visibility: visible;
        }
        &:after {
          visibility: visible;
        }
      }
      &:after {
        content: '';display: block;clear:both;
      }
    }
    .use {
      margin-top:10px;font-size: 14px;color:#494949;
      span {
        color:#f75950;margin-right: 10px;
      }
    }
    .nodis {
      color:#ccc;font-size: 14px;
      button {
        background: none;color:#3f69bb;font-size: 14px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
	}
}
</style>
