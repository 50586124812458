<!-- 结算页 -->
<template>
  <div class="settlebill">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='结算页'></logotitle>
      </el-col>
      <el-col :span="12">
        <buystep class='buystep'></buystep>
      </el-col>
    </el-row>
    <h6 class='h6'>填写并核对订单信息</h6>
    <setaddress v-if='setaddressif' class='setaddress' @exportDataInit='exportDataInit' @exportAdress='exportAdress' :address='address'></setaddress>
    <productinfo class='productinfo' @backcar='backCar' @exportProduct='exportProduct' :buylist='buylist' :optionsObj1='optionsObj1' :shipPrice='shipPrice' :weight='weight'></productinfo>
    <!-- <invoice class='invoice' :voicePrice='invoicePrice' @exportInvoice='exportInvoice'></invoice> -->
    <usediscount class='usediscount' @exportCount='exportCount' :discountlist='discountlist'></usediscount>
    <div class="result">
      <div class="group-result">
        <div class="lt">买家留言：</div>
        <div class="rt"><input type="text" v-model='buyusersay' maxlength="100"  placeholder="选填"></div>
      </div>
      <div class="group-result">
        <div class="lt"><span>{{productnum?productnum:0}}</span>件商品，总金额：</div>
        <div class="rt"><span>&yen;</span>{{productAllmoney | formatPrice}}</div>
      </div>
      <div class="group-result">
        <div class="lt">运费：</div>
        <div class="rt">
          <el-popover v-show='shipPrice==0'
            placement="left"
            width="200"
            trigger="hover"
            content="您选择的收货地址为包邮范围，请放心购买。">
            <button class="tip" slot="reference">i</button>
          </el-popover>
          <span>&yen;</span>{{shipPrice | formatPrice}}
        </div>
      </div>
      <div class="group-result">
        <div class="lt">优惠抵扣：</div>
        <div class="rt"><span>-&yen;</span>{{discount | formatPrice}}</div>
      </div>
      <div class="group-result group-result-Info">
        <div class="lt">应付总额：</div>
        <div class="rt"><span>&yen;</span>{{(productAllmoney - discount+shipPrice) | fullprice}}</div>
      </div>
      <div class="group-result-infos" v-if='addressinfos'>
        <p>{{addressinfos.phone | formatPhoneHide(4)}}</p>
        <p >收货人：{{addressinfos.name}}</p>
        <p>邮寄地址：{{addressinfos.select.province+' '+addressinfos.select.city+' '+addressinfos.select.area+' '+addressinfos.addressinfo}}</p>
      </div>
    </div>
    <div class="menus" >
      <!-- <button @click='backCar'>返回购物车</button> -->
      <button @click='postproduct'>提交订单</button>
    </div>
  </div>
</template>
<script>
import {mixin} from '../assets/js/common.js' //公用js
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import buystep from '@/components/buystep/buystep.vue';//购物步骤
import setaddress from '@/components/setaddress/setaddress.vue';//收货人地址
import productinfo from '@/components/productinfo/productinfo.vue';//商品列表
import invoice from '@/components/invoice/invoice.vue';//发票信息
import usediscount from '@/components/usediscount/usediscount.vue';//使用优惠券
export default {
  name: 'settlebill',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  mixins: [mixin],//混入
  components: {
    logotitle,
    buystep,
    setaddress,
    productinfo,
    invoice,
    usediscount,
  },
  props: {
    msg: String
  },
  data(){
    return {
      htmltitle:'结算页-微微定',
      address:null,//地址信息
      productnum:null,//结算产品总数
      productAllmoney:0,//产品总价
      productShipmoney:0,//产品总运费
      invoicePrice:0,//传入发票价格
      invoiceinfo:null,//发票信息
      discount:0,//优惠券优惠价格
      buylist:[],//购买列表数据
      // options1:[],//选项卡---配送方式
      // value1:null,//选项卡配送方式值
      optionsObj1:{
        options:null,//选项卡---配送方式
        value:null,//选项卡配送方式值
      },
      discountlist:[],//优惠券列表
      weight:0,//产品重量
      shipPrice:0,//运费
      buyusersay:'',//买家留言
      addressChoiceId:null,//选择地址id
      discountIds:'',//优惠券ID默认空值
      setaddressif:false,//地址列表
      addressinfos:null,//邮寄地址
    }
  },
  methods:{
    backCar(){
      // 返回购物车
      // console.log(this.buylist)
      for(let i in this.buylist){
        this.buylist[i]._choice = true
      }
      window.sessionStorage.setItem('carlist',JSON.stringify(this.buylist))
      this._router({exp:false,catch:()=>{
        // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
        // this._open({path: "/accountlogin",query: {redirect:'buycar'}})
      },then:()=>{
        this.$router.push('/buycar.html')
      }})
    },
    exportAdress(v){
      // 地址信息
      // this.address = v
      if(v.method=='choice'){
        // 切换地址
        // console.log(this.address)
        for(let i in this.address){
          if(this.address[i].choice){
            this.addressChoiceId = this.address[i].AddressID
            this.addressinfos = this.address[i]
          }
        }
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/ObtainShipPrice?ShippingMethodID='+this.optionsObj1.value+'&JobIDs='+this._spf(this.$route.query._ids)+'&addressid='+this.addressChoiceId,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [];
                for(let i in res.data.Data.joblist){
                  var _obj = {}
                  // console.log(res.data.Data.joblist[i])
                  var _str = res.data.Data.joblist[i].JobName.indexOf('[');
                  var _end = res.data.Data.joblist[i].JobName.indexOf(']');
                  var _s = res.data.Data.joblist[i].JobName.substring(_str+1,_end)
                  _obj.url = this._src + res.data.Data.joblist[i].ImageUrl
                  _obj.name =res.data.Data.joblist[i].JobName.substring(0,_str)
                  _obj.number = _s.split('-')[2]
                  _obj.tip = _s.split('-')[1]
                  _obj.tip1 = _s.split('-')[0]
                  _obj.number1 = res.data.Data.joblist[i].JobNum
                  _obj.price = res.data.Data.joblist[i].Price
                  // _obj.express = res.data.Data.joblist[i].ShipPrice
                  _obj.ProductType = res.data.Data.joblist[i].ProductType
                  _obj.DzproductnumberName = res.data.Data.joblist[i].DzproductnumberName
                  _obj.DzspecificationName = res.data.Data.joblist[i].DzspecificationName
                  _obj.DzmaterialName = res.data.Data.joblist[i].DzmaterialName
                  _data.push(_obj)
                }
                this.$set(this,'shipPrice',res.data.Data.ShipPrice)
                this.$set(this,'buylist',_data)
                this.productAllmoney= 0;
                this.productShipmoney = 0;
                for(let i in this.buylist){
                  this.productAllmoney+=this.buylist[i].price*this.buylist[i].number1
                  // +this.buylist[i].express
                  // this.productShipmoney +=this.buylist[i].express
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
      }
    },
    exportProduct(v){
      // 产品信息
      if(v.method=='ship'){
        // 选择快递
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/ObtainShipPrice?ShippingMethodID='+v.value+'&JobIDs='+this._spf(this.$route.query._ids)+'&addressid='+this.addressChoiceId,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [];
                for(let i in res.data.Data.joblist){
                  var _obj = {}
                  var _str = res.data.Data.joblist[i].JobName.indexOf('[');
                  var _end = res.data.Data.joblist[i].JobName.indexOf(']');
                  var _s = res.data.Data.joblist[i].JobName.substring(_str+1,_end)
                  _obj.url = this._src + res.data.Data.joblist[i].ImageUrl
                  _obj.name =res.data.Data.joblist[i].JobName.substring(0,_str)
                  _obj.number = _s.split('-')[2]
                  _obj.tip = _s.split('-')[1]
                  _obj.tip1 = _s.split('-')[0]
                  _obj.number1 = res.data.Data.joblist[i].JobNum
                  _obj.price = res.data.Data.joblist[i].Price
                  // _obj.express = res.data.Data.joblist[i].ShipPrice
                  _obj.ProductType = res.data.Data.joblist[i].ProductType
                  _obj.DzproductnumberName = res.data.Data.joblist[i].DzproductnumberName
                  _obj.DzspecificationName = res.data.Data.joblist[i].DzspecificationName
                  _obj.DzmaterialName = res.data.Data.joblist[i].DzmaterialName
                  _data.push(_obj)
                }
                this.$set(this,'shipPrice',res.data.Data.ShipPrice)
                this.$set(this,'buylist',_data)
                this.productAllmoney= 0;
                this.productShipmoney = 0;
                for(let i in this.buylist){
                  this.productAllmoney+=this.buylist[i].price*this.buylist[i].number1
                  // +this.buylist[i].express
                  // this.productShipmoney +=this.buylist[i].express
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
      }
    },
    exportInvoice(v){
      // 发票信息
      if(v){
        if(v.data8==1){
          v.data7=''
        }
      }
      this.invoiceinfo = v
    },
    exportCount(v){
      // 优惠券数据
      if(v.method=='choice'){
        // 选择优惠券
        // console.log(this.discountlist)
        this.discount = 0;
        for(let i in this.discountlist){
          if(this.discountlist[i].choice){
            this.discount+=parseInt(this.discountlist[i].discount)
            this.discountIds = this.discountlist[i].Id
          }
        }
      }else{
        this.discount = 0;
        this.discountIds = ''
      }
      // this.discount = v
    },
    postproduct(){
      // console.log(this.$store.state.user.CustomerID,this.$route.query._ids,this.$route.query.nums,this.optionsObj1.value,this.buyusersay,this.discountIds,this.addressChoiceId)
      // return;
      if(!this.addressChoiceId) {
        return this.$MessageBox.alert('请添加收货人信息',{type:'error'});
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/SubmitOrder?JobIDs='+this._spf(this.$route.query._ids)+'&copies='+this._spf(this.$route.query.nums)+'&ShippingmethodID='+this.optionsObj1.value+'&BuyerMessage='+this.buyusersay+'&receiptId='+'&couponId='+this.discountIds+'&addressid='+this.addressChoiceId,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              if(res.data.Data.result=='success'){
                this.$router.push({path:'/payment',query:{ordername:res.data.Data.OrderName+'.html'}})
                // this._open({path:'/payment',query:{ordername:res.data.Data.OrderName}})
              }
              if(res.data.Data.result=='pay'){
                this.$router.push({path:'/payresult',query:{ordername:res.data.Data.OrderName+'.html'}})
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
    },
    countAll(){
      // 计算价格
      this.productnum = this.buylist.length
      for(let i in this.buylist){
        this.productAllmoney+=this.buylist[i].price*this.buylist[i].number1
        // +this.buylist[i].express
        // this.productShipmoney +=this.buylist[i].express
      }
    },
    exportDataInit(v){
      // 刷新地址列表
      var _data3 = [];
      for(let i in v){
        var _obj3 = {}
        _obj3.select = {}
        _obj3.name = v[i].ContactName
        // _obj3.select.province = v[i].ProvinceID
        _obj3.select.province = v[i].ProvinceName
        // _obj3.select.city = v[i].CityID
        _obj3.select.city = v[i].CityName
        // _obj3.select.area = v[i].AreaID
        _obj3.select.area = v[i].AreaName
        // _obj3.addressinfo = v[i].Address
        _obj3.addressinfo = v[i].spiltAdress
        _obj3.phone = v[i].Mobile
        _obj3.code = v[i].PostCode
        _obj3.default = v[i].IsDefault
        // _obj3.choice = v[i].IsDefault==1?true:false
        _obj3.choice = true
        // if(v[i].IsDefault==1){
        //   window.sessionStorage.setItem('choiceAddres',JSON.stringify(v[i]))
        // }
        if(_obj3.choice){
          window.sessionStorage.setItem('choiceAddres',JSON.stringify(v[i]))
        }
        _obj3.AddressID = v[i].AddressID
        _obj3.addresId = v[i].ProvinceID
        _obj3.addresId1 = v[i].CityID
        _obj3.addresId2 =v[i].AreaID
        _obj3.phoneL = v[i].Telephone?v[i].Telephone.split('-')[0]:''
        _obj3.phoneR = v[i].Telephone?v[i].Telephone.split('-')[1]:''
        _data3.push(_obj3)
      }
      for(let i in _data3){
        if(_data3[i].choice){
          this.addressChoiceId = _data3[i].AddressID
        }
      }
      // 默认选择
      var _sessionaddres = JSON.parse(window.sessionStorage.getItem('choiceAddres'))
      var _choice=false;
      if(_sessionaddres){
        for(let i in _data3){
          _data3[i].choice=false
        }
        for(let i in _data3){
          if(_data3[i].AddressID==_sessionaddres.AddressID){
            _data3[i].choice=true
          }
        }
      }else{
        for(let i in _data3){
          _data3[i].choice=false
        }
        for(let i in _data3){
          if(_data3[i].choice){
            _choice = true
            window.sessionStorage.setItem('choiceAddres',JSON.stringify(_data3[i]))
            // this.$emit('exportAdress',this.address[i]);//抛出数据
          }
        }
        if(!_choice&&_data3){
          _data3[0].choice=true
          // this.$emit('exportAdress',{item:this.address[0],method:'choice'});//抛出数据
        }
      }
      // 默认选择end
      this.$set(this,'address',_data3)
      // 刷新快递运费
       for(let i in this.address){
          if(this.address[i].choice){
            this.addressChoiceId = this.address[i].AddressID
            this.addressinfos = this.address[i]
          }
        }
        // console.log(this.addressinfos)
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/ObtainShipPrice?ShippingMethodID='+this.optionsObj1.value+'&JobIDs='+this._spf(this.$route.query._ids)+'&addressid='+this.addressChoiceId,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [];
                for(let i in res.data.Data.joblist){
                  var _obj = {}
                  var _str = res.data.Data.joblist[i].JobName.indexOf('[');
                  var _end = res.data.Data.joblist[i].JobName.indexOf(']');
                  var _s = res.data.Data.joblist[i].JobName.substring(_str+1,_end)
                  _obj.url = this._src + res.data.Data.joblist[i].ImageUrl
                  _obj.name =res.data.Data.joblist[i].JobName.substring(0,_str)
                  _obj.number = _s.split('-')[2]
                  _obj.tip = _s.split('-')[1]
                  _obj.tip1 = _s.split('-')[0]
                  _obj.number1 = res.data.Data.joblist[i].JobNum
                  _obj.price = res.data.Data.joblist[i].Price
                  // _obj.express = res.data.Data.joblist[i].ShipPrice
                  _obj.ProductType = res.data.Data.joblist[i].ProductType
                  _obj.DzproductnumberName = res.data.Data.joblist[i].DzproductnumberName
                  _obj.DzspecificationName = res.data.Data.joblist[i].DzspecificationName
                  _obj.DzmaterialName = res.data.Data.joblist[i].DzmaterialName
                  _data.push(_obj)
                }
                this.$set(this,'shipPrice',res.data.Data.ShipPrice)
                this.$set(this,'buylist',_data)
                this.productAllmoney= 0;
                this.productShipmoney = 0;
                for(let i in this.buylist){
                  this.productAllmoney+=this.buylist[i].price*this.buylist[i].number1
                  // +this.buylist[i].express
                  // this.productShipmoney +=this.buylist[i].express
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
    },
    initDatas(){
      // 初始化数据
      // 请求结算页数据
      var _choiceAddres = JSON.parse(window.sessionStorage.getItem('choiceAddres'))
      // console.log(_choiceAddres)
      if(_choiceAddres){
        this.addressChoiceId = _choiceAddres.AddressID
        
      }else{
        this.addressChoiceId = ''
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/ObtainOrderMessage?JobIDs='+this._spf(this.$route.query._ids)+'&copies='+this._spf(this.$route.query.nums)+'&chicoAdreessId='+this.addressChoiceId,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              var _data = [];
              var _data1 = [];
              var _data2 = [];
              var _data3 = [];
              for(let i in res.data.Data.orderBill.JobList){
                var _obj = {}
                var _str = res.data.Data.orderBill.JobList[i].JobName.indexOf('[');
                var _end = res.data.Data.orderBill.JobList[i].JobName.indexOf(']');
                var _s = res.data.Data.orderBill.JobList[i].JobName.substring(_str+1,_end)
                _obj.url = this._src + res.data.Data.orderBill.JobList[i].ImageUrl
                _obj.name =res.data.Data.orderBill.JobList[i].JobName.substring(0,_str)
                _obj.number = _s.split('-')[2]
                _obj.tip = _s.split('-')[1]
                _obj.tip1 = _s.split('-')[0]
                _obj.number1 = res.data.Data.orderBill.JobList[i].JobNum
                _obj.price = res.data.Data.orderBill.JobList[i].Price
                _obj.express = res.data.Data.orderBill.JobList[i].ShipPrice
                _obj.ProductType = res.data.Data.orderBill.JobList[i].ProductType

                _obj.DzproductnumberName = res.data.Data.orderBill.JobList[i].DzproductnumberName
                  _obj.DzspecificationName = res.data.Data.orderBill.JobList[i].DzspecificationName
                  _obj.DzmaterialName = res.data.Data.orderBill.JobList[i].DzmaterialName
                _data.push(_obj)
              }
              for(let i in res.data.Data.orderBill.ShippingmethodList){
                var _obj1 = {}
                _obj1.value = res.data.Data.orderBill.ShippingmethodList[i].ShippingMethodID
                _obj1.label = res.data.Data.orderBill.ShippingmethodList[i].ShippingMethodName
                _data1.push(_obj1)
              }
              for(let i in res.data.Data.orderBill.CouponcodeList){
                var _obj2 = {}
                _obj2.meet = res.data.Data.orderBill.CouponcodeList[i].ConsumptionAmount
                _obj2.time = res.data.Data.orderBill.CouponcodeList[i].UseEndDate
                _obj2.discount = res.data.Data.orderBill.CouponcodeList[i].Amount
                _obj2.Id = res.data.Data.orderBill.CouponcodeList[i].Id
                _obj2.choice = false
                _data2.push(_obj2)
              }
              for(let i in res.data.Data.orderBill.AddressList){
                var _obj3 = {}
                _obj3.select = {}
                _obj3.name = res.data.Data.orderBill.AddressList[i].ContactName
                // _obj3.select.province = res.data.Data.orderBill.AddressList[i].ProvinceID
                _obj3.select.province = res.data.Data.orderBill.AddressList[i].ProvinceName
                // _obj3.select.city = res.data.Data.orderBill.AddressList[i].CityID
                _obj3.select.city = res.data.Data.orderBill.AddressList[i].CityName
                // _obj3.select.area = res.data.Data.orderBill.AddressList[i].AreaID
                _obj3.select.area = res.data.Data.orderBill.AddressList[i].AreaName
                // _obj3.addressinfo = res.data.Data.orderBill.AddressList[i].Address
                _obj3.addressinfo = res.data.Data.orderBill.AddressList[i].spiltAdress
                _obj3.phone = res.data.Data.orderBill.AddressList[i].Mobile
                _obj3.code = res.data.Data.orderBill.AddressList[i].PostCode
                _obj3.default = res.data.Data.orderBill.AddressList[i].IsDefault
                _obj3.choice = res.data.Data.orderBill.AddressList[i].IsDefault==1?true:false
                _obj3.AddressID = res.data.Data.orderBill.AddressList[i].AddressID
                _obj3.addresId = res.data.Data.orderBill.AddressList[i].ProvinceID
                _obj3.addresId1 = res.data.Data.orderBill.AddressList[i].CityID
                _obj3.addresId2 = res.data.Data.orderBill.AddressList[i].AreaID
                _obj3.phoneL = res.data.Data.orderBill.AddressList[i].Telephone?res.data.Data.orderBill.AddressList[i].Telephone.split('-')[0]:''
                _obj3.phoneR = res.data.Data.orderBill.AddressList[i].Telephone?res.data.Data.orderBill.AddressList[i].Telephone.split('-')[1]:''
                _data3.push(_obj3)
              }
              if(!_choiceAddres){
                for(let i in _data3){
                  if(_data3[i].choice){
                    this.addressChoiceId = _data3[i].AddressID
                    this.addressinfos = _data3[i]
                  }
                }
              }else{
                for(let i in _data3){
                  if(_data3[i].AddressID==_choiceAddres.AddressID){
                    this.addressinfos = _data3[i]
                  }
                }
              }
              // console.log(this.addressinfos)
              this.$set(this,'weight',res.data.Data.orderBill.TotalWeight)
              this.$set(this,'shipPrice',res.data.Data.orderBill.ShipPrice)
              this.$set(this,'discountlist',_data2)
              var _optionsObj1 = {};
              _optionsObj1.options = _data1
              _optionsObj1.value = res.data.Data.orderBill.ShippingMethodID
              this.$set(this,'optionsObj1',_optionsObj1)
              this.$set(this,'buylist',_data)
              this.$set(this,'address',_data3)
              this.setaddressif = true;
              this.countAll()
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
      // end
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.initDatas();
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  destroyed(){
    window.sessionStorage.removeItem('choiceAddres')
  },
  filters:{
    fullprice(v){
      if(v<0){
        return 0;
      }else{
        return v;
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.settlebill {
  width: 100%;background: #fff;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;
    .buystep {
      float:right;margin-top:10px;
    }
  }
  .h6 {
    width: 1200px;margin:0 auto;font-size: 14px;font-weight:normal;color:#434343;font-size: 18px;height: 50px;line-height: 50px;margin-bottom:10px;margin-top:10px;
  }
  .setaddress {
    width: 1200px;margin:0 auto;margin-bottom:25px;
  }
  .productinfo {
    width: 1200px;margin:0 auto;margin-bottom:25px;
  }
  .invoice {
    width: 1200px;margin:0 auto;margin-bottom:25px;
  }
  .usediscount{
    width: 1200px;margin:0 auto;margin-bottom:30px;
  }
  .menus {
    width: 1200px;margin:0 auto;display: flex;justify-content: flex-end;padding-bottom:50px;
    button {
      width: 135px;height: 35px;font-size: 18px;border-radius: 5px;margin-left:20px;
      // &:nth-child(1) {
      //   background: #fff;border:1px solid #c1c1c1;color:#1b1b1b;
      // }
      &:nth-child(1) {
        background: #f74b5b;border:1px solid #f74b5b;color:#fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .result {
    width: 1200px;margin:0 auto;
    .group-result {
      display: flex;justify-content: flex-end;align-items: center;height: 30px;
      .lt {
        span {
          color:#c33237;
        }
      }
      .rt {
        display: flex;justify-content: flex-end;width: 130px;color:#2c2c2c;
        span {
          margin-right: 3px;
        }
        .tip {
          color:#ff9e0f;background: #fff;border:1px solid #ff9e0f;width: 14px;height: 14px;line-height: 14px;border-radius: 50%;font-size: 10px;margin-right: 4px;
        }
      }
      &:nth-child(1) {
        .rt {
          width: 360px;
          input {
            width: 340px;height: 28px;line-height: 28px;border:1px solid #e4e4e4;padding-left:10px;color:#333;
            &::placeholder {
              color:#9b9b9b;
            }
          }
        }
      }
      &:nth-child(2) {
        margin-top:15px;padding-right: 10px;
      }
      &:nth-child(3) {
        padding-right: 10px;
      }
      &:nth-child(4) {
        border-bottom:2px solid #797979;padding-bottom:15px;padding-right: 10px;
      }
      &:nth-child(5) {
        padding-right: 10px;
        .rt {
          color:#c33237;font-size: 20px;font-weight: bold;
          span {
            font-size: 20px;font-weight: bold;
          }
        }
      }
    }
    .group-result-Info {
      background: #f2f2f2;padding-top:10px;
    }
    .group-result-infos {
      background: #f2f2f2;zoom:1;padding-right:10px;height:50px;margin-bottom:20px;
      p {
        float:right;height: 30px;line-height: 30px;color:#797979;
        &:nth-child(1) {
          margin-left:5px;
        }
        &:nth-child(2) {
          margin-left:20px;
        }
      }
      &:after {
        content: '';display: block;clear:both;
      }
    }
  }
}
</style>
