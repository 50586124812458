<!-- 设置发票信息 -->
<template>
  <div class="setinvoice">
    <div class="box">
      <h6 class='h6'>填写/修改发票信息<button class="close" @click='close'><svg><use xlink:href="#iconshanchu"></use></svg></button></h6>
      <div class="head">
        <div class="group-head">
          <div class="lt">可开金额：</div>
          <div class="rt"><span class="sp">&yen;{{invoiceinfo.data}}</span></div>
        </div>
        <div class="group-head">
          <div class="lt">发票类型：</div>
          <div class="rt">增值税普通发票</div>
        </div>
        <div class="group-head">
          <div class="lt">开票内容：</div>
          <div class="rt">设计费</div>
        </div>
      </div>
      <el-tabs class='tab' v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="申请电子发票" name="0">
        </el-tab-pane>
        <el-tab-pane label="申请纸质发票" name="1">
        </el-tab-pane>
      </el-tabs>
      <div class='tabs'>
        <p class="tip">温馨提示：电子发票与纸质发票具有同等功效，建议您申请电子发票</p>
        <div class="group-from">
          <div class="lt"><i>*</i>本次开票金额</div>
          <div class="rt"><p><span>&yen;</span>{{invoiceinfo.data | formatPrice}}</p></div>
        </div>
        <div class="group-from">
          <div class="lt"><i>*</i>公司名称</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data1'></div>
        </div>
        <div class="group-from">
          <div class="lt"><i>*</i>纳税人识别号</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data2'></div>
        </div>
        <div class="group-from">
          <div class="lt"><i>*</i>公司注册地址</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data3'></div>
        </div>
        <div class="group-from">
          <div class="lt"><i>*</i>公司电话</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data4'></div>
        </div>
        <div class="group-from">
          <div class="lt"><i>*</i>开户行名称</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data5'></div>
        </div>
        <div class="group-from">
          <div class="lt"><i>*</i>开户行账号</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data6'></div>
        </div>
        <div class="group-from" v-show='activeName==0'>
          <div class="lt"><i>*</i>邮箱地址</div>
          <div class="rt"><input type="text" v-model='invoiceinfo.data7'></div>
        </div>
      </div>
      <div class="menus">
        <button @click='saveinfo'>确定</button>
        <button @click='close'>取消</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mixin,regEmail} from '../../assets/js/common.js' //公用js
export default {
  name: 'setinvoice',
  mixins: [mixin],//混入
  data(){
    return {
      activeName: '0',//切换开票类型
      invoiceinfo:{
        data:0,
        data1:'',
        data2:'',
        data3:'',
        data4:'',
        data5:'',
        data6:'',
        data7:'',
        data8:'',
      },//开票数据
    }
  },
  props: {
    // voicePrices: Number,
    invoiceinprops:Object
  },
  methods:{
    handleClick(){
      // 监听tab切换---判断是电子/纸质
      this.invoiceinfo.data8 = this.activeName
    },
    close(){
      // 关闭弹窗
      this.$emit('exportClose')
    },
    saveinfo(){
      // 保存开票信息
      if(this.invoiceinfo.data8==0){
        if(regEmail(this.invoiceinfo.data7)){//验证邮箱格式
          window.sessionStorage.setItem('invoiceinfo',JSON.stringify(this.invoiceinfo))
          this.$MessageBox.alert('保存成功',{type:'success'}).then(() => {
            if(this.invoiceinfo.data1==''||this.invoiceinfo.data2==''||this.invoiceinfo.data7==''){
              this.$emit('exportCloseInv')
            }
            this.$emit('exportClose')
          });
        }else{
          this.$MessageBox.alert('邮箱格式错误',{type:'error'})
        }
      }else{
        window.sessionStorage.setItem('invoiceinfo',JSON.stringify(this.invoiceinfo))
        this.$MessageBox.alert('保存成功',{type:'success'}).then(() => {
          if(this.invoiceinfo.data1==''||this.invoiceinfo.data2==''){
            this.$emit('exportCloseInv')
          }
          this.$emit('exportClose')
        });
      }
    }
  },
  mounted(){

  },
  watch:{
    invoiceinprops(v,v1){
      this.invoiceinfo = v
    },
    'invoiceinfo.data8'(v,v1){
      this.activeName = v
    }
  }
}
</script>
<style  lang="scss" scoped>
.setinvoice {
  width: 100%;height: 100%;position: fixed;top:0px;left:0px;background: rgba(0,0,0,0.6);z-index: 999;
  .box {
    width: 640px;height: 700px;background: #fff;position: absolute;top:calc((100% - 700px)/2);left:calc((100% - 640px)/2);border-radius: 10px;
    .h6 {
      height: 40px;line-height: 40px;background: #f3f3f3;color:#878787;padding-left:10px;font-size: 14px;border-top-right-radius: 10px;border-top-left-radius: 10px;
      .close {
        position: absolute;top:10px;right:10px;background: none;width: 20px;height: 20px;
        svg {
          fill: #878787;width: 20px;height: 20px;
        }
      }
    }
    .head {
      width: 550px;margin: auto;padding:10px 15px;border-bottom:1px solid #e1e1e1;
      .group-head {
        display: flex;justify-content: flex-start;align-items: center;height: 30px;
        .lt {
          color:#4e4e4e;
        }
        .rt {
          color:#8e8a8b;
          .sp {
            color:#ea6d73;
          }
        }
      }
    }
    /deep/.tab {
      width: 560px;margin:0 auto;margin-top:20px;
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__nav-wrap::after{
        display: none;
      }
      .el-tabs__item {
        border:1px solid #d1d1d1;border-radius: 5px;padding:0px;height: 30px;line-height: 30px;margin-right: 20px;width: 150px;text-align: center;
        &:hover {
          background: #f84951;color:#fff;border-color:#f84951;
        }
      }
      .is-active {
        background: #f84951;color:#fff;border-color:#f84951;
      }
    }
    .tabs {
      width: 560px;margin:0 auto;
      .tip {
        font-size:14px;color:#7f7f7f;margin-bottom:20px;
      }
      .group-from {
        display: flex;justify-content: flex-start;align-items: center;margin-bottom:15px;
        .lt {
          width: 120px;font-size: 14px;
          i {
            font-style: normal;vertical-align: -4px;font-size: 16px;margin-right: 3px;color:#d13136;visibility: hidden;
          }
        }
        .rt {
          input {
            border:1px solid #cfcfcf;width: 400px;height: 35px;font-size: 14px;padding-left:10px;margin-top:-4px;
          }
          p {
            border:1px solid #cfcfcf;width: 400px;height: 35px;font-size: 16px;padding-left:10px;line-height: 33px;margin-top:-4px;
            span {
              margin-right:3px;
            }
          }
        }
        &:nth-child(2){
          .rt {
            p {
              color:#1e9ee6;
            }
          }
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(9) {
          .lt {
            i {
              visibility: visible;
            }
          }
        }
      }
    }
    .menus {
      display: flex;width: 130px;justify-content: space-between;position: absolute;bottom:25px;left:calc((100% - 130px)/2);
      button {
        width: 60px;height: 30px;border:1px solid #d1d1d1;border-radius: 5px;background: #fff;font-size: 14px;color:#111111;
        &:nth-child(1) {
          background: #f84951;color:#fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
